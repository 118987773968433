
import React, { Component } from 'react';
import './Contact.css'
import { Button, Form, FormGroup, Label, Input, FormText, Spinner} from 'reactstrap';
import { MdDone} from 'react-icons/md';


class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_sending: false,
      sent: false
    };

    this. onFormSubmit = this. onFormSubmit.bind(this);
  }

  componentDidMount() {
    this.props.sendForm("interest", null);
  }

  onFormSubmit = async (e) => {
    e.preventDefault()
    this.setState(state => ({
      is_sending: true
    }));
    const name = e.target.elements.name.value;
    const email = e.target.elements.email.value;
    const number = e.target.elements.number.value;
    const entity = e.target.elements.entity.value;
    const concept = e.target.elements.concept.value;
    const form = {name: name, email: email, number: number, entity: entity, concept: concept}
    let formSent = await this.props.sendForm("evaluation", form);
    let self = this;
    if(formSent){
      setTimeout(function() {
        self.setState({ sent: true});
      }, 1000);
    }
  }

  render() {
    return (
      <div className='final_cost_container'>
        <p className="help_message"> {this.props.textData.contact_page.done} <br/> {this.props.textData.contact_page.cost}</p>
        <p className='final_cost_value'> {this.props.total_cost}€ </p>
        <p className="help_message"> <br/>{this.props.textData.contact_page.help} <br/> {this.props.textData.contact_page.leave_contact} </p>
        <Form className='form' onSubmit={this.onFormSubmit}>
          <FormGroup>
            <Input type="text" name="name" id="name" placeholder={this.props.textData.contact_page.name} />
          </FormGroup>
          <FormGroup>
            <Input type="email" name="email" id="email" placeholder={this.props.textData.contact_page.email} />
          </FormGroup>
          <FormGroup>
            <Input type="text" name="number" id="number" placeholder={this.props.textData.contact_page.telefon} />
          </FormGroup>
          <FormGroup>
            <Input type="select" name="entity" id="entity">
              <option>{this.props.textData.contact_page.person}</option>
              <option>{this.props.textData.contact_page.startup}</option>
              <option>tthis.props.textData.contact_page.other}</option>
              <option>Anderes</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Input type="textarea" name="concept" id="concept" placeholder={this.props.textData.contact_page.idea}/>
          </FormGroup>
          {!this.state.is_sending && !this.state.sent? <Button type="submit">{this.props.textData.contact_page.send_consulting_request}</Button> : null}
          {this.state.is_sending && !this.state.sent ? <Spinner style={{ width: '2rem', height: '2rem' }} /> : null}
          {this.state.sent ? <MdDone /> : null}
        </Form>
      </div>
    );
  }
}

export default Contact;
