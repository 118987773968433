
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import Welcome from './Welcome';
import Popup from './Popup';
import InputMenu from './InputMenu';
import Contact from './Contact.js';
import Logo from './Logo';
import PopupImpressum  from './PopupImpressum'
import { MdDeveloperMode } from 'react-icons/md';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { Line } from 'rc-progress';
import './AppContainer.css';
import detectBrowserLanguage from 'detect-browser-language'
import logo from '../logo.svg';
import { createBrowserHistory } from 'history';


class AppContainer extends Component {
	constructor(props) {
		super(props);
		let jsonData = require('./questions_en.json');
		let textData = require('./questions_en.json');
		this.state = {
			start_date: new Date(),
			current_page: 0,
			jsonData: jsonData,
			selected_items: [],
			popup_id: 0
		};

		this.onStartClicked = this.onStartClicked.bind(this);
		this.onItemSelected = this.onItemSelected.bind(this);
		this.onBackClicked = this.onBackClicked.bind(this);
		this.onBottomItemClicked = this.onBottomItemClicked.bind(this);
		this.onSendClicked = this.onSendClicked.bind(this);
    	this.calculateProgress = this.calculateProgress.bind(this);
    	this.loadAppStrings = this.loadAppStrings.bind(this);
    	this.onLanguageChanged = this.onLanguageChanged.bind(this);
	}

    componentDidMount() {
    const detectBrowserLanguage = require('detect-browser-language')
    let appLanguage = this.detectLanguage();
    this.setState({ appLanguage: appLanguage  }, this.loadAppStrings);
    let self = this;
    window.addEventListener(
        'hashchange',
        function () {
            console.log("Hash changed");
            let appLanguage = self.detectLanguage();
            self.setState({ appLanguage: appLanguage  }, self.loadAppStrings);
        },
        false
    );
  }



  detectLanguage(){
    //let history = useHistory();
    let history = createBrowserHistory();
    var appLanguage = "EN"
    if(window.location.hash.indexOf("/de") > -1){
      appLanguage = "DE";
    }else if(window.location.hash.indexOf("/en") > -1){
      appLanguage = "EN";
    }else{
    var userLanguage = detectBrowserLanguage().toUpperCase();
    if(userLanguage.indexOf("DE") > -1){
      appLanguage = "DE";
      history.push('/#/de/');
      }else{
        // Default english will be used
       history.push('/#/en/');
      }
      this.loadAppStrings()
    }
    return appLanguage;
  }

  loadAppStrings(){
       const { appLanguage } = this.state
   if(appLanguage == "DE"){
    let jsonData = require('./questions_de.json');
    let textData = require('./text_de.json');
    this.setState({jsonData: jsonData});
    this.setState({textData: textData});
   }else{
    let jsonData = require('./questions_en.json');
    let textData = require('./text_en.json');
    this.setState({jsonData: jsonData});
    this.setState({textData: textData});
   }
  }

	onStartClicked(e) {
		e.preventDefault();
		this.setState(state => ({
			current_page: 1
		}));
	}

	onItemSelected(e,selectedItem) {
		var currentQuestion = this.state.jsonData.data[this.state.current_page - 1].title;

		e.preventDefault();
		var nextPage = this.state.current_page + 1;
		var s= {page: this.state.current_page, item: selectedItem, question: currentQuestion}
		var selected = this.state.selected_items
		selected.push(s);

		this.setState(state => ({
			current_page: nextPage
		}));

		this.setState(state => ({
			selected_items: selected
		}));
	}

	onBackClicked(){
		if(this.isPopUp()){
			this.setState({ popup_id: 0});
			return;
		}

		var selected= this.state.selected_items;
		var pageToSet = this.state.current_page - 1;

		if(this.isLastPage()){
			selected = [];
			pageToSet = 0;
		}
		selected.splice(-1,1);
		this.setState(state => ({
			selected_items: selected
		}));


		this.setState(state => ({
			current_page: pageToSet
		}));
	}

	onBottomItemClicked(e){
		let id = e.target.id;
		if(id && id == "impressum"){
			this.setState({ popup_id: 1 });
		}else if(id && id == "contact"){
			this.setState({ popup_id: 2 });
		}
	}

	onLanguageChanged(e){
		let history = createBrowserHistory();
		let id = e.target.id;
		if(id && id == "DE"){
			this.setState({ appLanguage: "DE"  }, this.loadAppStrings);
			history.push('/#/de/');
		}else if(id && id == "EN"){
			this.setState({ appLanguage: "EN"  }, this.loadAppStrings);
			history.push('/#/en/');
		}
		this.loadAppStrings()
	}



	onSendClicked(type, data){

		var api = "https://berdev.de:3000/api/"
		switch (type){
			case "evaluation": api += "evaluation";
			break;
			case "interest": api += "interest";
			break;
			case "contact": api += "contact";
			break;
			default: return;
		}
		return new Promise(resolve => {
			try{
				var xhr = new XMLHttpRequest();
				xhr.open('POST', api, true);
				xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

				xhr.onreadystatechange = function () {
					// In local files, status is 0 upon success in Mozilla Firefox
					if(xhr.readyState === XMLHttpRequest.DONE) {
						var status = xhr.status;
						if (status >= 200 && status < 400) {
							resolve(true)
						} else {
							resolve(false)
						}
					}
				};
				var selectedData = this.state.selected_items;
				var dataToSend = {...data, ...selectedData}
				xhr.send(JSON.stringify(dataToSend));
			}catch(e){
				resolve(false);
			}
		});
	}

	calculateTotalCost(selectedItems){
		var total = 0;
		for(var i=0; i<selectedItems.length; i++){
			total += selectedItems[i].item.value;
		}
		return total;
	}

  calculateProgress(){
  return (this.state.current_page/this.state.jsonData.data.length) * 100;
  }

	getBackName(){
		if(window.innerWidth < 600){
			return "";
		}

		if(this.isPopUp()){
			return this.state.textData.navigation.back
		}
		if(this.isLastPage()){
			return this.state.textData.navigation.newCalculation
		}
		return this.state.textData.navigation.back
	}

	isFirstPage(){
		return this.state.current_page === 0;
	}

	isLastPage(){
		return this.state.current_page > this.state.jsonData.data.length;
	}

	isPopUp(){
		return this.state.popup_id > 0;
	}

	isMobile(){
		return window.innerWidth < 600
	}

	/*shouldComponentUpdate(nextProps, nextState) {
		return this.state.current_page != nextState.current_page;
	}
	*/


	render() {

		if(!this.isFirstPage() && !this.isLastPage()){
			var currentQuestion = this.state.jsonData.data[this.state.current_page - 1].title;
			var d= this.state.jsonData.data[[this.state.current_page - 1]].items;
		}
        const circleContainerStyle = {
      width: '250px',
      height: '250px',
      display: 'inline-block',
    };

    	if(!this.state.textData){
    		return (
    		<div className="App-body">
      		
    		</div>
  		);
    	}
		return (
			<div className="App-body">
				{ this.isFirstPage() && !this.isPopUp() ?  <div className="logo-small-left"> <Logo/> </div>: null }
				{ this.isFirstPage() && !this.isPopUp() ? <MdDeveloperMode className='App-logo'/> : null }

				{!this.isFirstPage() || this.isPopUp() ? <p className="back_button" onClick={this.onBackClicked}> {<IoMdArrowRoundBack /> } {this.getBackName()} </p> : null  }
				{!this.isFirstPage() && !this.isLastPage() && !this.isPopUp() ? <p className="total_cost"> {this.calculateTotalCost(this.state.selected_items)}€ </p> : null  }
				{ this.isFirstPage() && !this.isPopUp()?  <Welcome textData={this.state.textData} onStartClicked={this.onStartClicked}/> : null  }
				<div className="menu">
					{ !this.isFirstPage() && !this.isLastPage() && !this.isPopUp() ? <p className='current_question'> {currentQuestion} </p>: null  }
					{ !this.isFirstPage() && !this.isLastPage() && !this.isPopUp() ?  <InputMenu jsonData={d} onItemSelected={this.onItemSelected}/> : null  }

					{!this.isMobile() && !this.isFirstPage() && !this.isLastPage() && !this.isPopUp() ? <p className='current_page'> <Line percent={this.calculateProgress()} strokeWidth="2" strokeColor="#D3D3D3" />{this.state.current_page}/{this.state.jsonData.data.length} </p> : null  }
				</div>
				{ this.isLastPage() || this.isPopUp()? <div className="logo_small_right"> <Logo/> </div> : null }
				{ this.isLastPage() && !this.isPopUp() ? <Contact textData={this.state.textData} total_cost={this.calculateTotalCost(this.state.selected_items)} sendForm={this.onSendClicked}/> : null }
				<div className="bottom_bar"> <span id="impressum" className="bottom_item" onClick={this.onBottomItemClicked}>{this.state.textData.welcome_page.impressumButton}</span> - <span id="contact" className="bottom_item" onClick={this.onBottomItemClicked}>{this.state.textData.welcome_page.contactButton}</span> </div>
				<div className="bottom_bar_left"> <span id="EN" className={this.detectLanguage() == "EN" ? 'bottom_item_active' : 'bottom_item_not_active'} onClick={this.onLanguageChanged}>English</span> - <span id="DE" className={this.detectLanguage() == "DE" ? 'bottom_item_active' : 'bottom_item_not_active'} onClick={this.onLanguageChanged}>Deutsch</span> </div>
					{this.isPopUp() && this.state.popup_id == 2 ? <Popup textData={this.state.textData} sendForm={this.onSendClicked}/> : null }
					{this.isPopUp() && this.state.popup_id == 1 ? <PopupImpressum textData={this.state.textData} /> : null }
				</div>
			);
		}
	}

	export default AppContainer;
