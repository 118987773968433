import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './PopupImpressum.css';
import contactImg from '../contact.png';


class PopupImpressum extends Component {


  render() {
    return (
      <div>
        <img src={contactImg} />
</div>
);
}


}

export default PopupImpressum;
