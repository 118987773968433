import React, { Component } from 'react';
import InputItem  from './InputItem.js';
import './InputMenu.css';

class InputMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var listItems = this.props.jsonData.map((d) => <InputItem key={d.name} itemData={d} onItemClicked={ this.props.onItemSelected}/>);
    return (
      <div className="topContainer">
        {listItems }
      </div>
    );
  }


}

export default InputMenu;
