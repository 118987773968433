import React, { Component } from 'react';
import './InputItem.css'
import { FaAndroid, FaAppStoreIos, FaMobileAlt, FaPuzzlePiece, FaGamepad, FaHammer, FaWarehouse, FaThumbsUp, FaThumbsDown, FaAdversal} from 'react-icons/fa';
import { MdDone, MdDoneAll, MdThumbsUpDown, MdMonetizationOn, MdEmail } from 'react-icons/md';
import { TiPuzzleOutline, TiSocialDribbble, TiSocialFacebook} from 'react-icons/ti';
import { GiPuzzle } from 'react-icons/gi';
import { IoIosMore } from 'react-icons/io';
import { AiTwotoneApi } from 'react-icons/ai'

class InputItem extends Component {

	constructor(props) {
		super(props);
		this.state = {
		};
	}

	iconNameForIcon(iconName) {
		switch(iconName) {
			case 'FaAndroid':
			return <FaAndroid size={52}/>
			case 'FaAppStoreIos':
			return <FaAppStoreIos size={52}/>
			case 'FaMobileAlt':
			return <FaMobileAlt size={52}/>
			case 'MdDone':
			return <MdDone size={52}/>
			case 'MdDoneAll':
			return <MdDoneAll size={52}/>
			case 'FaPuzzlePiece':
			return <FaPuzzlePiece size={52}/>
			case 'TiPuzzleOutline':
			return <TiPuzzleOutline size={52}/>
			case 'GiPuzzle':
			return <GiPuzzle size={52}/>
			case 'TiSocialDribbble':
			return <TiSocialDribbble size={52}/>
			case 'FaGamepad':
			return <FaGamepad size={52}/>
			case 'FaHammer':
			return <FaHammer size={52}/>
			case 'FaWarehouse':
			return <FaWarehouse size={52}/>
			case 'IoIosMore':
			return <IoIosMore size={52}/>
			case 'FaThumbsUp':
			return <FaThumbsUp size={42}/>
			case 'FaThumbsDown':
			return <FaThumbsDown size={42}/>
			case 'MdThumbsUpDown':
			return <MdThumbsUpDown size={52}/>
			case 'FaAdversal':
			return <FaAdversal size={52}/>
			case 'MdMonetizationOn':
			return <MdMonetizationOn size={52}/>
			case 'MdEmail':
			return <MdEmail size={52}/>
			case 'TiSocialFacebook':
			return <TiSocialFacebook size={52}/>
			case 'AiTwotoneApi':
			return <AiTwotoneApi size={52}/>
			default:
			return 'foo';
		}
		return <FaAndroid size={52}/>
	}


	render() {
		return (
			<div className='item_container' onClick={(e) => this.props.onItemClicked(e,this.props.itemData)}>
				<div className='item-image'>
					{ this.iconNameForIcon(this.props.itemData.icon)}
				</div>
				<div className='item-title'>
					{this.props.itemData.name}
				</div>

			</div>
		);
	}


}

export default InputItem;
