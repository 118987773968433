import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './Welcome.css';
import { MdDeveloperMode } from 'react-icons/md';


class Welcome extends Component {

  render() {
    return (
      <div>
        <p className="title"> {this.props.textData.welcome_page.title}</p>
        <p className="sub-title"> {this.props.textData.welcome_page.subtitle}</p>
        <Button color="primary" onClick={this.props.onStartClicked}> {this.props.textData.welcome_page.button}</Button>
      </div>
    );
  }
}

export default Welcome;
