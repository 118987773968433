import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './Logo.css';
import { MdDeveloperMode } from 'react-icons/md';


class Logo extends Component {
  render() {
    return (
      <div> <MdDeveloperMode size={30}/>
      <span className="brand-first">Ber</span>
      <span className="brand-second">dev</span>
    </div>
  );
}
}

export default Logo;
